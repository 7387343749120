import React, { useState } from 'react';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';
import NavButton from './NavButton';
import { useClient } from '../../../react-hooks/useClient';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Container } from 'aurelia-dependency-injection';
import { useFlash } from '../../../react-hooks/useFlash';
import { StandardActions } from '../../../action/standard-actions';

const TimeTrackingButton = ({ user }) => {
  const { t } = useAureliaI18n();
  const [loading, setLoading] = useState(false);
  const client = useClient();
  const ea = Container.instance.get(EventAggregator);
  const actions = Container.instance.get(StandardActions);
  const flash = useFlash();
  const [startedTracking, setStartedTracking] = useState(user?.startedTracking);
  const startTimeTracking = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    return client.post('human-resources/start-user-tracking').then(
      (data) => {
        setLoading(false);

        ea.publish('sio_form_post_submit', {
          config: { modelId: 'user/user' },
        });
        setStartedTracking(true);
        return data;
      },
      (error) => {
        setLoading(false);
        let data = error.data;
        if (data && data.localizedMessage) {
          flash.error(data.localizedMessage);
        }
        return error;
      }
    );
  };

  const stopTimeTracking = () => {
    if (loading) {
      return;
    }

    setLoading(true);

    return client.post('human-resources/stop-user-tracking').then(
      (data) => {
        setLoading(false);

        ea.publish('sio_form_post_submit', {
          config: { modelId: 'user/user' },
        });

        if (!data?.data?.id) {
          return data;
        }

        return actions
          .getAction(
            {
              type: 'view',
              viewId: 'hr-time-tracking/time-log-manage',
              modal: true,
            },
            {
              id: data.data.id,
            }
          )
          .action()
          .then(() => {
            setStartedTracking(false);
          });
      },
      (error) => {
        setLoading(false);

        let data = error.data;

        if (data && data.localizedMessage) {
          flash.error(data.localizedMessage);
        }

        return error;
      }
    );
  };
  return !startedTracking ? (
    <NavButton
      title={t('human-resources.startTracking')}
      onClick={startTimeTracking}
    >
      <span>
        <i className={`fa fa-play fa-sm`}></i>
      </span>
    </NavButton>
  ) : (
    <NavButton
      title={t('human-resources.stopTracking')}
      onClick={stopTimeTracking}
    >
      <span>
        <i className={`fa fa-pause fa-sm`}></i>
      </span>
    </NavButton>
  );
};

export default TimeTrackingButton;
